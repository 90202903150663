
.imprint {
  color: black;
  margin-right: 20px;
  margin-left: 10px;
  font-family: "Fira Sans", "Helvetica", "Arial", sans-serif;
  height: 100%;
}

.imprint h3 {
  font-weight: 400;
}

.imprint li {
  /*font-family: "Fira Sans", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  font-weight: 300;*/
}
