
.policy {
  color: black;
  margin-right: 20px;
  margin-left: 10px;
  font-family: "Fira Sans", "Helvetica", "Arial", sans-serif;
  height: 100%;
}

.policy p {
  margin-top: 0px;
}

.header {
  font-weight: 500;
}

.subheader {
  margin-bottom: 0;
  font-weight: 500;
}

.highlight {
  font-weight: bold;
}
